<template>
    <div>
        <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
            <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>
        <v-row>
            <div style="position: fixed; z-index: 1;  background-color: white; width: 100%; top:55px;">
            <v-breadcrumbs :items="items" large style=" font-weight: 600; letter-spacing: 1px; ">

            </v-breadcrumbs>
            </div>
        </v-row><br><br>
        <br />
        <v-card class="card">
            <v-card-title class="heading justify-center">
                <span>Book Category</span>
            </v-card-title>
            <div v-if="init_loading">
          <v-skeleton-loader v-bind="attrs" type="three-line,  article"></v-skeleton-loader>
        </div>
        <div v-else>
            <v-card-text>
                <div class="add-section">
                    <div style="text-align: center;">
                        <v-col>
                            <v-form ref="form">
                                <label class="add-text">Add Book Category</label><span style="color: red;"> *</span>
                                <v-text-field class="text" v-model="bookCat" outlined dense :rules="[rules.required]"
                                    clearable required></v-text-field>
                                <label class="add-text">Add Display Order</label><span style="color: red;"> *</span>
                                <v-text-field class="text" type="number" :min="0" v-model="display_order" outlined dense clearable
                                    required></v-text-field>
                                <label class="add-text">Add Short Name</label>
                                <v-text-field class="text" v-model="shortname" outlined dense clearable
                                    required></v-text-field>
                                <label class="add-text">Add Library Department</label>
                                <v-autocomplete dense v-model="seleteddept" :items="erpDepartment" item-value="departmentId"
                                    item-text="department" outlined></v-autocomplete>
                                <v-btn color="success" @click="addBookCat">Add</v-btn>
                            </v-form>
                        </v-col>
                    </div>
                </div>

                <template>
                    <div class="text-center">
                        <v-dialog v-model="dialog" width="500">
                            <v-card>
                                <v-card-title class="grey lighten-2">
                                    Edit Book Category
                                </v-card-title>

                                <v-card-text style="padding-bottom: 0px;">
                                    <label class="add-text">Book Category</label><span style="color: red;"> *</span>
                                    <v-text-field class="text" v-model="bookCatName" outlined dense
                                        :rules="[rules.required]" clearable required></v-text-field>
                                    <label class="add-text">Display Order</label><span style="color: red;"> *</span>
                                    <v-text-field class="text" v-model="display_order" type="number" :min="0" outlined dense clearable
                                        required></v-text-field>
                                    <label class="add-text">Short Name</label>
                                    <v-text-field class="text" v-model="shortname" outlined dense clearable
                                        required></v-text-field>
                                    <label class="add-text">Library Department</label>
                                    <v-select dense v-model="seleteddept" :items="erpDepartment" item-value="departmentId"
                                        item-text="department" persistent-hint outlined></v-select>
                                </v-card-text>

                                <!-- <v-divider></v-divider> -->

                                <div class="text-center">
                                    <v-spacer></v-spacer>
                                    <v-btn class="m-3" color="error" @click="dialog = false">
                                        Close
                                    </v-btn>
                                    <v-btn class="m-3" color="success" @click="saveEdit()">Update</v-btn>
                                </div>
                            </v-card>
                        </v-dialog>
                    </div>
                </template>

                <div v-if="loader">
          <v-skeleton-loader v-bind="attrs" type="three-line,  article"></v-skeleton-loader>
        </div>
        <div v-else>
                <template v-if="bookCat_list != null">
                    <v-data-table :headers="headers" :items="bookCat_list" :search="search" class="elevation-1 nowrap">
                        <template v-slot:top>
                            <v-toolbar flat color="white">
                                <v-spacer></v-spacer>
                                <v-text-field v-model="search" append-icon="mdi-magnify" label="Search in table" single-line
                                    hide-details></v-text-field>
                            </v-toolbar>
                        </template>
                        <template v-slot:item.edit="{ item }">
                            <!-- <v-icon v-if="item.edit==true" color="success" class="mr-2">mdi-check-circle-outline</v-icon> -->
                            <v-icon v-if="item" class="mr-2" @click="updateBookCat(item)">mdi mdi-pencil-box</v-icon>
                        </template>
                        <template v-slot:item.actions="{ item }">
                            <!-- <v-btn :loading="edit_btn_load" @click="editItem(item)" class="link" color="info"> <v-icon title="Activate/Deactivate the User Type">mdi-recycle</v-icon></v-btn> -->
                            <v-switch v-model="item.bookCatIsactive" @change="editItem(item)"></v-switch>
                        </template>
                        <template v-slot:item.delete="{ item }">
                            <v-icon v-if="item" class="mr-2" @click="deleteBookCategory(item)"> mdi mdi-delete</v-icon>
                        </template>
                    </v-data-table>
                </template>
                </div>
            </v-card-text>
            </div>
        </v-card>
        <!-- <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay> -->
    </div>
</template>

<script>
import axios from "axios";
import "@/assets/css/color.css"; // css needs to be imported for each dashboard
export default {
    data: () => ({
        display_order: null,
        init_loading: false,
        loader: false,
        snackbar_msg: "",
        snackbar: false,
        color: "",
        org: null,
        dialog: false,
        search: "",
        bookCat: null,
        bookCatId: null,
        bookCatName: null,
        userType: null,
        edit_btn_load: false,
        editUserType: null,
        editUserTypeId: null,
        erpDepartment: null,
        seleteddept: null,
        shortname: null,
        items: [
            {
                text: 'Dashboard',
                disabled: false,
                href: '/home',
            },
            {
                text: localStorage.getItem("jhhjbyj"),
                disabled: false,
                href: '/sublink',
            },
            {
                text: 'Admin Master',
                disabled: false,
                href: '/admin-master',
            },
            {
                text: 'Book Category',
                disabled: true,
                href: '',
            },
        ],
        rules: {
            required: (value) => !!value || "Required.",
        },
        bookCat_list: null,
        headers: [
            { text: "Sr no", value: "srno" },
            { text: "Name", value: "bookCat" },
            { text: "Department", value: "erpdepartment" },
            { text: "Short Name", value: "shortname" },
            { text: "Edit", value: "edit" },
            { text: "Toggle", value: "actions", sortable: false },
            { text: "Delete", value: "delete" },
        ],
    }),
    watch: {},
    methods: {
        showSnackbar(clr, msg) {
            this.snackbar = true;
            this.color = clr;
            this.snackbar_msg = msg;
        },
        editItem(item) {
            console.log(item);
            axios
                .post("/admin/bookCatIsactive", item)
                .then(() => {
                    // this.sec_mode_list = res.data.user_list;
                    this.showSnackbar("#4CAF50", "Book Category updated successfully...");
                })
                .catch((error) => {
                    this.showSnackbar("#b71c1c", "Something went wrong!!!");
                    console.log(error);
                })
                .finally(() => {
                    this.edit_btn_load = false;
                    axios;
                });
        },

        updateBookCat(item) {
            //  alert(item.erpdepartment);
            this.dialog = true;
            this.bookCatName = item.bookCat;
            this.bookCatId = item.bookCatId;
            this.display_order = item.displayorder;
            this.shortname = item.shortname;
            this.seleteddept = item.erpdepartmentId;
        },

        saveEdit(item) {
            if (this.bookCatName) {
                this.bookCatName = this.bookCatName.trim()
            }
            if(!this.bookCatName){
                this.showSnackbar("#b71c1c", "Please Enter Book Category");
                return;
            }
            else if(!this.display_order){
                this.showSnackbar("#b71c1c", "Please Enter Display Order");
                return;
            }
            const data = {
                bookCatName: this.bookCatName,
                bookCatId: this.bookCatId,
                displayorder: this.display_order,
                shortname: this.shortname,
                erpDepartment: this.seleteddept
            };
            // alert(this.seleteddept)
            
                this.loader = true;
                axios
                    .post("/admin/editBookCat", data)
                    .then((res) => {
                        if (res.data.msg == "200") {
                            this.loader = false;
                            this.sec_mode_list = res.data.bookCat_list;
                            this.showSnackbar("#4CAF50", "Book Category updated successfully...");
                            this.onload();
                            this.dialog = false;
                        } else {
                            this.showSnackbar("#b71c1c", res.data.msg);
                            this.loader = false;
                        }
                    })
                    .catch((error) => {
                        this.showSnackbar("#b71c1c", "Something went wrong!!!");
                        console.log(error);
                    })
                    .finally(() => {
                        this.edit_btn_load = false;
                        // this.editUserType = null;
                        axios;
                    });
          
        },
        deleteBookCategory(item) {
            const data = {
                bookCatId: item.bookCatId,
            };
            axios
                .post("/admin/deleteBookCategory", data)
                .then((res) => {
                    if (res.data.msg == "200") {
                        this.showSnackbar("#4CAF50", "Book Category Deleted Successfully  !!");
                        this.onload();
                    } else if (res.data.msg == "exp") {
                        this.showSnackbar("#b71c1c", "Can not be Deleted!!!");
                    }
                })
                .catch((error) => {
                    this.showSnackbar("#b71c1c", "Something went wrong!!!");
                    window.console.log(error);
                });
        },

        onload() {
            this.init_loading = true
            axios
                .post("/admin/getBookCat")
                .then((res) => {
                    this.org = res.data.org;
                    this.bookCat_list = res.data.bookCat;
                    this.init_loading = false
                    this.erpDepartment = res.data.department_list
                    console.log(this.bookCat_list);
                    //this.showSnackbar("#4CAF50","Success")
                })
                .catch((error) => {
                    this.showSnackbar("#b71c1c", "Something went wrong!!!");
                    console.log(error);
                })
                .finally(() => {
                    this.init_loading = false
                });
        },

        addBookCat() {
            if (this.bookCat) {
                this.bookCat = this.bookCat.trim()
            }
            if(!this.bookCat){
                this.showSnackbar("#b71c1c", "Please Enter Book Category");
                return;
            }
            else if(!this.display_order){
                this.showSnackbar("#b71c1c", "Please Enter Display Order");
                return;
            }
            const data = {
                bookCat_name: this.bookCat,
                displayorder: this.display_order,
                shortname: this.shortname,
                erpDepartment: this.seleteddept
            };

                this.loader = true;
                axios
                    .post("/admin/addBookCat", data)
                    .then((res) => {
                        this.org = res.data.org;
                        this.bookCat_list = res.data.bookCat_list;
                        if (res.data.msg == "200") {
                            this.loader = false;
                            this.showSnackbar("#4CAF50", "Book Category added successfully...");
                            // this.userType = null;
                            this.$refs.form.reset()
                        } else {
                            this.showSnackbar("#b71c1c", res.data.msg);
                        }
                    })
                    .catch((error) => {
                        this.showSnackbar("#b71c1c", "Something went wrong!!!");
                        console.log(error);
                        this.loader = false;
                    })
                    .finally(() => { });
            
        },
    },
    mounted() {
        this.onload();
    },
};
</script>
<style scoped>
.heading {
    background-color: #3f51b5;
    padding: 0.3rem;
    color: white;
}

.v-icon {
    cursor: pointer;
}

.text>>>.v-input__slot {
    background: white !important;
    border-color: hsla(190, 99%, 30%, 1) !important;
}
</style>
